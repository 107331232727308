import { useState, useRef, useEffect, useCallback } from 'react'

export const useRecursiveTimeout = (callback, delay) => {
    const [isRunning, setIsRunning] = useState(false)
    const play = useCallback(() => {
        if (delay !== Infinity) setIsRunning(true)
    }, [setIsRunning])
    const reset = useCallback(() => {
        setIsRunning(false), play()
    }, [setIsRunning])
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!isRunning) return
        let id = 0

        const tick = () => {
            if (!isRunning) return clearTimeout(id)
            savedCallback.current()
            requestAnimationFrame(() => (id = setTimeout(tick, delay)))
        }
        requestAnimationFrame(() => (id = setTimeout(tick, delay)))

        return () => {
            if (id) clearTimeout(id)
            reset()
        }
    }, [isRunning, delay, reset])

    return { play, reset }
}
