import React, { useState, useEffect, useCallback } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import { DotButton, PrevButton, NextButton } from './EmblaCarouselButtons'
import '../styles/embla.css'
import { useRecursiveTimeout } from './useRecursiveTimeout'

const EmblaCarouselComponent = ({
    children,
    options,
    style,
    dots = true,
    AUTOPLAY_INTERVAL = Infinity
}) => {
    const [emblaRef, embla] = useEmblaCarousel(options)
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const autoplay = useCallback(() => {
        if (!embla) return
        if (embla.canScrollNext()) {
            embla.scrollNext()
        } else {
            embla.scrollTo(0)
        }
    }, [embla])
    const { play, reset } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL)

    const scrollNext = useCallback(() => {
        if (!embla) return
        embla.scrollNext()
        reset()
    }, [embla, reset])

    const scrollPrev = useCallback(() => {
        if (!embla) return
        embla.scrollPrev()
        reset()
    }, [embla, reset])

    const scrollTo = useCallback(
        index => {
            if (!embla) return
            embla.scrollTo(index)
            reset()
        },
        [embla, reset]
    )

    const onSelect = useCallback(() => {
        if (!embla) return
        setSelectedIndex(embla.selectedScrollSnap())
        setPrevBtnEnabled(embla.canScrollPrev())
        setNextBtnEnabled(embla.canScrollNext())
    }, [embla])

    useEffect(() => {
        if (!embla) return
        setScrollSnaps(embla.scrollSnapList())
        onSelect()
        embla.on('select', onSelect)
        embla.on('pointerDown', reset)
    }, [embla, onSelect, reset])

    useEffect(() => {
        play()
    }, [play])

    return (
        <div className="embla" style={style}>
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {children.map((Child, index) => (
                        <div className="embla__slide" key={index}>
                            <div className="embla__slide__inner">{Child}</div>
                        </div>
                    ))}
                </div>
            </div>
            {dots && (
                <div className="embla__dots">
                    {scrollSnaps.map((snap, index) => (
                        <DotButton
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                            key={index}
                        />
                    ))}
                </div>
            )}
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
    )
}

export default EmblaCarouselComponent
